<template>
  <div class="nav" v-loading="loading">
    <el-tabs
      class="navTabs"
      :data="getTa"
      @tab-click="handleClickTB"
      ref="aaa"
      type="card"
      v-model="level1"
    >
      <el-tab-pane
        @tab-click="handleClickTB(index)"
        :key="index"
        :label="i.name"
        :name="i.uuid"
        v-for="(i, index) in getTa"
        :style="`'background: '${
          articleData.length > 0 ? '#F7F7F7' : '#ffffff'
        }`"
      >
      </el-tab-pane>
    </el-tabs>

    <el-tabs
      class="navTabs2"
      :data="getTable"
      @tab-click="handleClick"
      ref="aaa"
      type="card"
      v-model="level2"
    >
      <el-tab-pane
        :key="index"
        :label="i.name"
        :name="i.uuid"
        v-for="(i, index) in getTable"
        :style="`'background: '${
          articleData.length > 0 ? '#F7F7F7' : '#ffffff'
        }`"
      >
      </el-tab-pane>
    </el-tabs>
    <el-tabs class="navTabs2S">
      <div
        style="
          width: 100%;
          height: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
        v-if="articleData.length == 0"
      >
        <div style="text-align: center">
          <div>{{this.zanwu}}</div>
        </div>
      </div>
      <div class="tobBoxShadow" v-if="articleData.length > 0"></div>
      <div
        class="articleWrap"
        :class="articleData.length < 5 ? 'lessNumber5' : ''"
      >
        <div
          :key="index"
          @click="detail(item)"
          class="contents"
          v-for="(item, index) in articleData"
        >
          <div class="imgWrap">
            <img
              :src="item.img_host + item.img_path"
              width="100%"
              height="100%"
            />
          </div>
          <p :title="item.name" class="articleWrapDetails">
            {{ item.name }}
          </p>
        </div>
      </div>
    </el-tabs>
    <!-- 搜索框 -->
    <div class="searchArea queryArea">
      <div class="selectInput clearfix">
        <el-input
          class="input-with-select please"
          clearable
          @keyup.enter.native="getList(1)"
          placeholder="请输入关键字"
          style="width: 200px"
          suffix-icon="iconfont iconsousuo"
          v-model="searchval"
        ></el-input>
        <el-button class="searchBtn" @click="getList(1)" round>搜索</el-button>
      </div>
    </div>
    <div class="block">
      <el-pagination
        :current-page="currentPage"
        :page-size="10"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, jumper,  ->, prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      zanwu:'',
      activeN: "",
      getTa: [],
      loading: true, // 加载中
      activeName: "",
      input: "",
      currentPage: 1,
      getTable: [],
      level2: "",
      level1: "",
      cate_uuid: "",
      uuid: "",
      total: 0,
      articleData: [],
      image: this.$baseURL,
      searchval: "",
      // searchSelect: "",
      tableDataAll: [],
    };
  },
  created() {
    this.getCate();
  },
  mounted() {
    setTimeout(() => {
      this.setImgHeight();
    }, 2000);
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    setTimeout(() => {
      this.zanwu = '暂无数据';
    }, 2000);
    console.log(this.articleData.length);
    window.onresize = () => {
      this.setImgHeight();
    };
  },
  methods: {
    // 通过js来设定图片的高度
    setImgHeight() {
      let imgWrap = document.querySelectorAll(".imgWrap");
      imgWrap.forEach((value) => {
        value.style.height = (value.offsetWidth * 3) / 4 + "px";
      });
    },

    getCate() {
      // this.getTa = []
      let data = {
        type: 2,
      };
      this.$axios
        .post("/p/articleCate/all", this.$qs(data))
        .then((res) => {
          if (res.data.code === 0) {
            this.getTa = res.data.data;
            this.level1 = this.getTa[0].uuid;
            res.data.data.forEach((item) => {
              if (item.uuid == this.level1) {
                this.getTable = [];
                if (item.children) {
                  this.getTable = item.children;
                  var tmp = { uuid: this.level1, name: "全部" };
                  this.getTable.unshift(tmp);
                  this.level2 = tmp.uuid;
                  this.cate_uuid = this.level2;
                }

                console.log(this.getTa);
              console.log(this.getTable);
              } else {
                /* 默认第一个uuid  */
                this.level2 = this.getTa[0].uuid;
              }
            });
            console.log(this.getTable);
            this.cate_uuid = this.level2;
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: "数据获取失败",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getList(query) {
      this.loading = true;
      let quer = {
        query,
        cate_uuid: this.level2,
        type: 2,
        keyword: this.searchval,
        page: this.currentPage,
        status: 1,
        sort: 1,
      };
      this.$axios
        .post("/p/nutrition/lists", this.$qs(quer))
        .then((res) => {
          if (res.data.code === 0) {
            this.articleData = res.data.data.rows;
            // console.log(this.articleData);
            this.total = res.data.data.total;
            setTimeout(() => {
              this.setImgHeight();
              this.loading = false;
            }, 800);
          } else {
            this.$message({
              type: "error",
              message: "数据获取失败",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 一级点击
    handleClickTB(tab, event) {
      // console.log(tab.name);
      console.log(tab);
      this.getTable = [];
      this.$axios
        .post("/p/articleCate/all", this.$qs({ type: 2 }))
        .then((res) => {
          if (res.data.code === 0) {
            res.data.data.forEach((item) => {
              console.log(item.uuid);
              console.log(this.level1);
              if (item.uuid == this.level1) {
                this.getTable = [];
                if (item.children) {
                  this.getTable = item.children;
                  var tmp = { uuid: this.level1, name: "全部" };
                  this.getTable.unshift(tmp);
                  this.level2 = tmp.uuid;
                  this.cate_uuid = this.level2;
                }
              } else {
                // console.log(tab.name);
                // this.getTable = [];
                // var aa = { uuid: tab.name, name: "全部" };
                // this.getTable.unshift(aa);
                /* 获取点击当前的这个uuid */
                this.level2 = tab.name;
                this.cate_uuid = this.level2;
              }
            });
            this.getList();
          } else {
            this.$message({
              type: "error",
              message: "数据获取失败",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 二级点击
    handleClick(tab, event) {
      this.getList();
    },
    //路由跳转详情页
    detail(i) {
      this.$router.push({
        path: `/nutritionManagement/nourishmentSon/dietaryDetails?type=2&uuid=${i.uuid}&sp=1`,
      });
    },
    //分页器
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.loading = true;
      this.currentPage = val;
      this.getList(val);
    },
    // 搜索内容
    // search() {
    //   this.loading = true;
    //     title = this.searchval;
    //     keyword = this.searchval;
    //   this.currentPage = 1;
    //   this.getList();
    // },
  },
  watch: {
    $route(to, from) {
      this.getCate();
    },
  },
};
</script>
<style lang="scss" scoped>
/*bar 的全宽盒子*/
.nav {
  position: relative;
  background-color: #fff;
  .navTabs {
    & /deep/ .el-tabs__header.is-top {
      padding-top: 20px;
      border-bottom: 0;
      margin-bottom: -10px !important;
      height: 50px;
      background-color: #fff;
      .el-tabs__nav-wrap.is-top {
        margin-left: 20px;

        .el-tabs__header .el-tabs__item {
          border-left: 0 !important;
        }

        /*整体*/
        .el-tabs__nav.is-top {
          /*box-shadow: 2px 2px 8px 0 rgba(223, 223, 223, 1);*/
          border-radius: 4px;
          overflow: hidden;
          /*height: 40px;*/
          border: 0 !important;
        }

        /*m每一个小盒子 去除左边框竖线*/
        .el-tabs__item {
          border-left: 0;
          min-width: 120px;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          border-radius: 4px;
          color: #333333;
          padding: 0 5px;
          margin-right: 10px;

          &:hover {
            color: #0055e9;
          }

          /*选中状态*/
          &.is-active {
            background: #e5f0f8;
            color: #0055e9;
          }
        }

        @media (max-width: 1200px) {
          .el-tabs__item {
            width: 100px;
          }
        }

        /*bar 的整体盒子*/
        .el-tabs__nav-scroll {
          height: 50px;
          background: #fff;
        }
      }
    }

    & /deep/ .el-tabs__content {
      // background: #f7f7f7;
      background-color: #fff;

      .el-tab-pane {
        background: #fff;
      }

      .nonumber {
        width: 100%;
        text-align: center;
        color: #262626;
      }

      .tobBoxShadow {
        display: block;
        width: 100%;
        overflow: hidden;
        margin-right: -20px;
        // background: #f7f7f7;
        background-color: #fff;
        // padding-bottom: 20px;
        height: 2px;
        box-shadow: 0 2px 8px 0 rgba(189, 189, 189, 1);
      }

      .articleWrap {
        /*width: 100%;*/
        overflow: hidden;
        // background: #f7f7f7;
        background-color: #fff;
        padding-left: 22px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .contents {
          width: 19%;
          height: 100%;
          margin-bottom: 20px;
          border-radius: 4px;
          overflow: hidden;
          background: #fff;
          margin-right: 1%;
          border: 2px solid #eee;
          &:hover {
            box-shadow: 0 2px 8px 0 #e3e3e3;
            border: 2px solid #0055e9;
            .imgWrap {
              .el-image {
                transform: scale(1.1);
                transition: all 0.8s;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .knowMoreAbout {
                opacity: 1;
                transition: all 0.8s;
              }
            }
          }

          .imgWrap {
            position: relative;
            overflow: hidden;
            width: 100%;
            /*height: 140px;*/
            pointer-events: none;

            .el-image {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            /*了解更多*/
            .knowMoreAbout {
              position: absolute;
              right: 0;
              bottom: 0;
              margin: 0;
              width: 90px;
              height: 35px;
              line-height: 35px;
              background: rgba(36, 147, 231, 1);
              border-radius: 4px 0 0 4px;
              font-size: 14px;
              color: rgba(255, 255, 255, 1);
              text-align: center;
              pointer-events: none;
              border-bottom-left-radius: 0;
              // opacity: 0;
            }
          }

          .articleWrapTitle {
            font-weight: 600;
            margin-top: 10px;
            font-size: 16px;
            color: rgba(51, 51, 51, 1);
            padding: 0 20px;
            overflow: hidden;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .articleWrapDetails {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: rgba(102, 102, 102, 1);
            padding: 0 20px;
            margin-top: 10px;
            margin-bottom: 20px;
            height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        @media (max-width: 1200px) {
          .contents {
            width: 18%;
          }
        }

        div:nth-child(5n + 5) {
          margin-right: 0;
        }

        div:hover {
          box-shadow: 0 2px 8px 0 rgba(189, 189, 189, 1);
          cursor: pointer;
        }
      }
    }
  }
  .navTabs2 {
    & /deep/ .el-tabs__header.is-top {
      // padding-top: 20px;
      border-bottom: 0;
      margin-bottom: -5px !important;
      height: 50px;
      background-color: #fff;
      .el-tabs__nav-wrap.is-top {
        margin-left: 20px;

        .el-tabs__header .el-tabs__item {
          border-left: 0 !important;
        }

        /*整体*/
        .el-tabs__nav.is-top {
          /*box-shadow: 2px 2px 8px 0 rgba(223, 223, 223, 1);*/
          border-radius: 4px;
          overflow: hidden;
          /*height: 40px;*/
          border: 0 !important;
        }

        /*m每一个小盒子 去除左边框竖线*/
        .el-tabs__item {
          border-left: 0;
          min-width: 120px;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          border-radius: 4px;
          color: #333333;
          padding: 0 5px;
          margin-right: 10px;

          &:hover {
            color: #0055e9;
          }

          /*选中状态*/
          &.is-active {
            background: #fff !important;
            color: #0055e9;
          }
        }

        @media (max-width: 1200px) {
          .el-tabs__item {
            width: 100px;
          }
        }

        /*bar 的整体盒子*/
        .el-tabs__nav-scroll {
          height: 50px;
          background: #fff;
        }
      }
    }

    & /deep/ .el-tabs__content {
      background: #f7f7f7;

      .el-tab-pane {
        background: #fff;
      }

      .nonumber {
        width: 100%;
        text-align: center;
        color: #262626;
      }

      .tobBoxShadow {
        display: block;
        width: 100%;
        overflow: hidden;
        margin-right: -20px;
        // background: #f7f7f7;
        background-color: #fff;
        padding-bottom: 20px;
        height: 2px;
        box-shadow: 0 2px 8px 0 rgba(189, 189, 189, 1);
      }

      .articleWrap {
        /*width: 100%;*/
        overflow: hidden;
        // background: #f7f7f7;
        background-color: #fff;
        padding-left: 22px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .contents {
          width: 18%;
          height: 100%;
          margin-bottom: 20px;
          border-radius: 7px;
          overflow: hidden;
          background: #fff;
          margin-right: 1%;
          border: 2px solid #eee;

          &:hover {
            box-shadow: 0 2px 8px 0 #e3e3e3;
            border: 2px solid #0055e9;

            .imgWrap {
              .el-image {
                transform: scale(1.1);
                transition: all 0.8s;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .knowMoreAbout {
                opacity: 1;
                transition: all 0.8s;
              }
            }
          }

          .imgWrap {
            position: relative;
            overflow: hidden;
            width: 100%;
            /*height: 140px;*/
            pointer-events: none;

            .el-image {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            /*了解更多*/
            .knowMoreAbout {
              position: absolute;
              right: 0;
              bottom: 0;
              margin: 0;
              width: 90px;
              height: 35px;
              line-height: 35px;
              background: rgba(36, 147, 231, 1);
              border-radius: 4px 0 0 4px;
              font-size: 14px;
              color: rgba(255, 255, 255, 1);
              text-align: center;
              pointer-events: none;
              border-bottom-left-radius: 0;
              // opacity: 0;
            }
          }

          .articleWrapTitle {
            font-weight: 600;
            margin-top: 10px;
            font-size: 16px;
            color: rgba(51, 51, 51, 1);
            padding: 0 20px;
            overflow: hidden;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .articleWrapDetails {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: rgba(102, 102, 102, 1);
            padding: 0 20px;
            margin-top: 10px;
            margin-bottom: 20px;
            height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        @media (max-width: 1200px) {
          .contents {
            width: 18%;
          }
        }

        div:nth-child(5n + 5) {
          margin-right: 0;
        }

        div:hover {
          box-shadow: 0 2px 8px 0 rgba(189, 189, 189, 1);
          cursor: pointer;
        }
      }
    }
  }
  .navTabs2S {
    & /deep/ .el-tabs__content {
      // background: #f7f7f7;
      background-color: #fff;

      .el-tab-pane {
        background: #fff;
      }

      .nonumber {
        width: 100%;
        text-align: center;
        color: #262626;
      }

      .tobBoxShadow {
        display: block;
        width: 100%;
        overflow: hidden;
        margin-right: -20px;
        // background: #f7f7f7;
        margin-top: -10px;
        background-color: #fff;
        // padding-bottom: 20px;
        // padding-bottom: 7px;s
        height: 2px;
        box-shadow: 0 2px 8px 0 rgba(189, 189, 189, 1);
      }

      .articleWrap {
        /*width: 100%;*/
         width: 100%;
        overflow: hidden;
        // background: #f7f7f7;
        background-color: #fff;

        padding-left: 22px;
        // padding-right: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .contents {
          position: relative;
          width: 18%;
          height: 100%;
          margin-bottom: 20px;
          border-radius: 7px;
          overflow: hidden;
          background: #fff;
          // margin-right: 1%;
          // border: 2px solid #fff;
          margin: 0.5%;
          border: 2px solid #eee;

          &:hover {
            box-shadow: 0 2px 8px 0 #e3e3e3;
            border: 2px solid #0055e9;
            .imgWrap {
              .el-image {
                transform: scale(1.1);
                transition: all 0.8s;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .knowMoreAbout {
                opacity: 1;
                transition: all 0.8s;
              }
            }
          }

          .imgWrap {
            position: relative;
            overflow: hidden;
            width: 100%;
            /*height: 140px;*/
            pointer-events: none;

            .el-image {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            /*了解更多*/
            .knowMoreAbout {
              position: absolute;
              right: 0;
              bottom: 0;
              margin: 0;
              width: 90px;
              height: 35px;
              line-height: 35px;
              background: rgba(36, 147, 231, 1);
              border-radius: 4px 0 0 4px;
              font-size: 14px;
              color: rgba(255, 255, 255, 1);
              text-align: center;
              pointer-events: none;
              border-bottom-left-radius: 0;
              // opacity: 0;
            }
            .threeLevel {
              position: absolute;
              right: 0;
              bottom: 0;
              background-color: #0055e9;
              color: #fff;
              padding: 2px 3px;
              // height: 30px;
              text-align: center;
              // line-height: 30px;
              padding: 8px;
              border-radius: 7px 0 0 7px;
            }
          }

          .articleWrapTitle {
            font-weight: 600;
            margin-top: 10px;
            font-size: 16px;
            color: rgba(51, 51, 51, 1);
            padding: 0 20px;
            overflow: hidden;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .articleWrapDetails {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: rgba(102, 102, 102, 1);
            padding: 0 20px;
            margin-top: 10px;
            margin-bottom: 20px;
            height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        @media (max-width: 1200px) {
          .contents {
            width: 18%;
          }
        }

        div:nth-child(5n + 5) {
          margin-right: 0;
        }

        div:hover {
          box-shadow: 0 2px 8px 0 rgba(189, 189, 189, 1);
          cursor: pointer;
        }
      }
    }
  }
  .searchArea {
    position: absolute;
    right: 0px;
    top: 20px;

    .selectInput {
      width: 400px;

      .el-select {
        width: 130px;
      }

      .el-input {
        & /deep/ .el-input-group__append {
          background: #409eff;
          border-color: #409eff;
        }
      }
    }
  }

  .block {
    .el-pagination {
      padding: 0 20px;
    }
  }
}
/deep/ .clearfix {
  .el-input .el-input__inner {
    border-radius: 0;
    border-right-width: 0;
    border: none;
    border-bottom: 2px solid #ccc;
  }

  .el-input .el-input__inner:focus {
    border-color: #0055e9;
  }

  .el-select {
    float: left;
  }

  .el-select .el-input__inner {
    border-right-width: 0;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #ccc;
  }

  .el-select .el-input__inner:focus {
    border-color: #0055e9;
  }

  .el-select .el-input__inner:focus {
    border-right-width: 1px;
  }

  .bot_content {
    p {
      display: flex;
      align-items: center;
      width: 100%;

      i {
        font-size: 22px;
      }

      span {
        font-size: 18px;
      }
    }
  }
}
.searchBtn {
  width: 100px;
  border: 1px solid #0055e9;
  color: #0055e9;
  margin-left: 20px;
}
</style>
